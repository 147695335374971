@import "../../style/common.scss";
.alert {
  color: white;
  position: fixed;
  z-index: 1111;
  width: 90%;
  display: block;
  margin-left: 5%;
  text-align: center;
  border-radius: 6px;
  @media screen and (min-width: $break) {
    width: 60%;
    margin-left: 20%;
    font-size: 1.2rem;
  }
}
.message {
  margin-bottom: 0.2rem;
}
.error {
  background-color: #d61a3c;
}
.success {
  background-color: #3d9e74;
}
.warning {
  background-color: #e26313;
}
