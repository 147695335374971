@import url(https://fonts.googleapis.com/css?family=IBM+Plex+Sans:400,500,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=IBM+Plex+Sans:400,500,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=IBM+Plex+Sans:400,500,600,700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

*{margin:0;padding:0;box-sizing:border-box}html,body,div#root,div.App{width:100%;height:100%}ul{list-style-type:none}a{text-decoration:none;color:inherit}*{font-family:IBM Plex Sans}body::-webkit-scrollbar{display:none}input::-webkit-outer-spin-button,input::-webkit-inner-spin-button{-webkit-appearance:none;margin:0}input[type=number]{-moz-appearance:textfield}body{-ms-overflow-style:none}.color-red{color:#ed2b2b}.color-grey{color:rgba(0,0,0,.54)}.color-blue{color:#073c52}.bold{font-weight:bold}div.router-footer-container{position:absolute;top:8%;width:100%}div.header-container{position:fixed;background:#fff;border-bottom:.0625rem solid rgba(0,0,0,.05);width:100%}div.queries{background-image:linear-gradient(#f6fbfd, #fff);color:rgba(0,0,0,.7);padding:5%;width:100%;font-size:1rem}@media screen and (max-width: 600px){div.queries{width:100%}}
.Loader_loader__2hZcM{position:fixed;width:5.625rem;left:0;right:0;top:0;bottom:0;margin:auto;display:inline-block}@media screen and (min-width: 600px){.Loader_loader__2hZcM{width:7.5rem}}.Loader_mainDiv__13gxY{position:fixed;width:100%;height:100vh;background-image:linear-gradient(#f6fbfd, #fff);z-index:100;top:0}
.Flash_colorRed__1tdqR{color:#ed2b2b}.Flash_colorGrey__1wQ78{color:rgba(0,0,0,.54)}.Flash_colorBlue__2TYEI{color:#073c52}.Flash_colorBlack__3Y-Sq{color:#000}.Flash_alert__w3t3D{color:#fff;position:fixed;z-index:1111;width:90%;display:block;margin-left:5%;text-align:center;border-radius:6px}@media screen and (min-width: 600px){.Flash_alert__w3t3D{width:60%;margin-left:20%;font-size:1.2rem}}.Flash_message__mTYUG{margin-bottom:.2rem}.Flash_error__1_r5u{background-color:#d61a3c}.Flash_success__1uE9j{background-color:#3d9e74}.Flash_warning__2f6W7{background-color:#e26313}
.CrossButton_colorRed__1BnL_{color:#ed2b2b}.CrossButton_colorGrey__sxnX9{color:rgba(0,0,0,.54)}.CrossButton_colorBlue__1R5fy{color:#073c52}.CrossButton_colorBlack__22gM5{color:#000}.CrossButton_cross__2JpM1{margin:auto;margin-bottom:.3rem;cursor:pointer;display:inline-block}.CrossButton_cross__2JpM1 div{margin-top:.5rem;background-color:rgba(0,0,0,.54);height:.125rem;width:1.2rem}.CrossButton_cross__2JpM1 div:nth-child(1){-webkit-transform:rotate(45deg) translateY(0.45rem);transform:rotate(45deg) translateY(0.45rem)}.CrossButton_cross__2JpM1 div:nth-child(2){-webkit-transform:rotate(-45deg) translateY(-0.45rem);transform:rotate(-45deg) translateY(-0.45rem)}
