@import "./colors.scss";

.colorRed {
  color: $red;
}
.colorGrey {
  color: $grey;
}
.colorBlue {
  color: $blue;
}
.colorBlack {
  color: $black;
}
