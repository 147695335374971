$red: #ed2b2b;
$black: #000000;
// 50 shades of Grey ;-)
$grey-level-0_01: rgba(0, 0, 0, 0.01);
$grey-level-0_02: rgba(0, 0, 0, 0.02);
$grey-level-0_05: rgba(0, 0, 0, 0.05);
$grey-level-0_1: rgba(0, 0, 0, 0.1);
$grey-level-0_3: rgba(0, 0, 0, 0.3);
$grey-level-0_4: rgba(0, 0, 0, 0.4);
$grey-level-0_5: rgba(0, 0, 0, 0.5);
$grey: rgba(0, 0, 0, 0.54);
$grey-level-0_6: rgba(0, 0, 0, 0.6);
$grey-level-0_7: rgba(0, 0, 0, 0.7);
$grey-level-0_8: rgba(0, 0, 0, 0.8);

$blue: #073c52;
$blue-text: #178aba;
$blue-text-footer: #0c5a7a;
$light-blue: #f6fbfd;
$progress-background: #f4fafc;
$light-blue-background: #f2fafd;
$blue-home: #f7fcfe;
$white: #fff;
$route-color: rgba(0, 0, 0, 0.54);
$active-route-color: #178aba;
$footer-background: #fcfcfc;
$grey-border: #c1c1c1;
