@import "./reset.scss";
@import "./colors.scss";
@import "./fonts.scss";
@import "./variables.scss";
@import "./mixins.scss";

* {
  font-family: $ibm-plex;
}
body::-webkit-scrollbar {
  display: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* Hide scrollbar for IE and Edge */
body {
  -ms-overflow-style: none;
}
.color-red {
  color: $red;
}
.color-grey {
  color: $grey;
}
.color-blue {
  color: $blue;
}

.bold {
  font-weight: bold;
}

div.router-footer-container {
  position: absolute;
  top: 8%;
  width: 100%;
}

div.header-container {
  position: fixed;
  background: $white;
  border-bottom: 0.0625rem solid $grey-level-0_05;
  width: 100%;
}

//** Queries **//
div.queries {
  background-image: linear-gradient($light-blue, $white);
  color: $grey-level-0_7;
  padding: 5%;
  width: 100%;
  font-size: 1rem;
  @media screen and (max-width: $break) {
    width: 100%;
  }
}
