@import "../../style/variables.scss";
@import "../../style/colors.scss";

.loader {
  position: fixed;
  width: 5.625rem;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  display: inline-block;
  @media screen and (min-width: $break) {
    width: 7.5rem;
  }
}
.mainDiv {
  position: fixed;
  width: 100%;
  height: 100vh;
  background-image: linear-gradient($light-blue, $white);
  z-index: 100;
  top: 0;
}
